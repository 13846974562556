import { format } from 'date-fns';
import { gql } from 'graphql-request';
import { FnfUpdateResponseSchemaContract } from 'schemas/FnfUpdateResponseSchema';
import { GetFnfResponseSchemaContract } from 'schemas/GetFnfResponseSchema';
import { graphQLApi } from 'utils/Api';
import { dateFormats } from 'utils/Dates';
import { rupeeAmountToPaise } from 'utils/Numbers';
import {
  ComponentAssignmentRequestInput,
  LopData,
  PayrollUpdateFnfDataResponse,
  PayrollUpdateFnfDataVariables,
} from '../types';

const updateFnfMutation = gql`
  mutation Mutation(
    $deactivateDate: String!
    $additions: [ComponentAssignmentRequestInput!]!
    $deductions: [ComponentAssignmentRequestInput!]!
    $lopData: LopDaysUpdateInput!
    $userData: PayrollUserUpdateInput!
  ) {
    payrollUpdateFnfData(
      deactivateDate: $deactivateDate
      additions: $additions
      deductions: $deductions
      lopData: $lopData
      userData: $userData
    ) {
      code
      success
      message
      data {
        success
        payslipLink
        payrollMonth
        runpayrollLink
        messageType
      }
    }
  }
`;

const mapAdditions = (
  additions: Exclude<GetFnfResponseSchemaContract['additions'], null> = [],
  effectiveFrom: string,
  effectiveTo: string,
): ComponentAssignmentRequestInput[] => {
  return additions
    .filter((comp) => comp.id && parseInt(rupeeAmountToPaise(comp.amount).toString()) > 0)
    .map((component) => ({
      componentId: component.id as string,
      value: rupeeAmountToPaise(component.amount).toString(),
      effectiveFrom,
      effectiveTo,
    }));
};

const mapDeductions = (
  deductions: Exclude<GetFnfResponseSchemaContract['deductions']['non_lop_deductions'], null> = [],
  effectiveFrom: string,
  effectiveTo: string,
): ComponentAssignmentRequestInput[] => {
  return deductions
    .filter((comp) => comp.id && parseInt(rupeeAmountToPaise(comp.amount).toString()) > 0)
    .map((component) => ({
      componentId: component.id as string,
      value: rupeeAmountToPaise(component.amount).toString(),
      effectiveFrom,
      effectiveTo,
    }));
};

const mapLopData = (lopDays: string = '0', peopleId: string, payrollMonth: string): LopData => ({
  data: [
    {
      peopleId: parseInt(peopleId),
      lopDays: parseInt(lopDays),
    },
  ],
  payrollMonth,
  source: 'fnf',
});

const mapUserData = (
  userId: string,
  deactivateDate: string,
  reasonForLeaving?: string | null,
  personalEmailAddress?: string | null,
) => ({
  userId,
  deactivateDate,
  reasonForLeaving,
  personalEmailAddress,
});

const transformToGraphQLVariables = (
  fnfData: GetFnfResponseSchemaContract,
  dismissalDate: Date,
  peopleId: string,
): PayrollUpdateFnfDataVariables => {
  const effectiveFrom = format(dismissalDate, dateFormats.yearMonthDate);
  const effectiveTo = effectiveFrom;

  return {
    deactivateDate: effectiveFrom,
    additions: mapAdditions(fnfData.additions || [], effectiveFrom, effectiveTo),
    deductions: mapDeductions(
      fnfData.deductions.non_lop_deductions || [],
      effectiveFrom,
      effectiveTo,
    ),
    lopData: mapLopData(fnfData.deductions.lop_deduction?.lop_days, peopleId, effectiveFrom),
    userData: mapUserData(
      peopleId,
      effectiveFrom,
      fnfData.reason_for_leaving,
      fnfData.personal_email_address,
    ),
  };
};

export const updateFnfDetails = async (
  fnfData: GetFnfResponseSchemaContract,
  dismissalDate: Date,
  peopleId: string,
): Promise<FnfUpdateResponseSchemaContract> => {
  try {
    const queryVariables = transformToGraphQLVariables(fnfData, dismissalDate, peopleId);

    const response = await graphQLApi<PayrollUpdateFnfDataResponse, PayrollUpdateFnfDataVariables>({
      query: updateFnfMutation,
      queryVariables,
    });

    return response.payrollUpdateFnfData.data;
  } catch (error: unknown) {
    throw error;
  }
};
