import { DeductionComponentV2 } from 'components/RunPayroll/utils/types';
import { DeductionsComponentType, EarningsComponentType } from 'components/SalaryComponents/types';
import {
  deductFrom,
  earningPayTypes,
  taxDeductionBehavior,
} from 'components/WizardViews/SalaryComponents/constants';
import { format } from 'date-fns';
import { dateFormats } from 'utils/Dates';
import { rupeeAmountToPaise } from 'utils/Numbers';
import { PayrollAllowanceV2, UpdateAdditionsDeductionsLopPayload } from './types';
import { generateUserId } from 'utils/commons';

export const convertDataToUpdateAdditionsDeductionsPayload = ({
  allowances,
  deductions,
  userId,
  payrollMonth,
}: {
  allowances: PayrollAllowanceV2[];
  deductions: DeductionComponentV2;
  userId: string;
  payrollMonth: string;
}): UpdateAdditionsDeductionsLopPayload => {
  const additionsObject = allowances
    .filter((allowances) => allowances.id !== '')
    .filter((allowances) => parseInt(allowances.amount.toString()) > 0)
    .map((allowances) => {
      return {
        componentId: allowances.id || '',
        value: String(rupeeAmountToPaise(allowances.amount)),
        effectiveFrom: format(new Date(payrollMonth), dateFormats.yearMonth),
        effectiveTo: format(new Date(payrollMonth), dateFormats.yearMonth),
      };
    });

  const deductionsObject = deductions.non_lop_deductions
    .filter((deductions) => deductions.id !== '')
    .filter((deductions) => parseInt(deductions.amount.toString()) > 0)
    .map((deductions) => {
      return {
        componentId: deductions.id || '',
        value: String(rupeeAmountToPaise(deductions.amount)),
        effectiveFrom: format(new Date(payrollMonth), dateFormats.yearMonth),
        effectiveTo: format(new Date(payrollMonth), dateFormats.yearMonth),
      };
    });

  return {
    userId: generateUserId(userId),
    additions: additionsObject,
    deductions: deductionsObject,
    lopData: {
      payrollMonth: payrollMonth,
      data: [
        {
          peopleId: Number(userId),
          lopDays: Number(deductions.lop_deduction.lop_days),
        },
      ],
      source: 'manual',
    },
  };
};

export const getSearchDropdownDescription = (
  salaryComponent: EarningsComponentType | DeductionsComponentType,
) => {
  if (salaryComponent.category === 'EARNINGS_CATEGORY') {
    salaryComponent = salaryComponent as EarningsComponentType;
    return `${earningPayTypes[salaryComponent.settings.earningDetails.payType].label} 
    ${
      salaryComponent.settings.behaviour.taxBehaviour?.taxDeductionBehaviour
        ? `| ${
            taxDeductionBehavior[
              salaryComponent.settings.behaviour.taxBehaviour?.taxDeductionBehaviour
            ]?.label
          }`
        : ''
    }`;
  } else if (salaryComponent.category === 'DEDUCTIONS_CATEGORY') {
    salaryComponent = salaryComponent as DeductionsComponentType;
    return `Deduct from ${deductFrom[salaryComponent.settings.deductionDetails.deductFrom].label}`;
  }
};

export const filterSalaryComponents = <T extends EarningsComponentType | DeductionsComponentType>(
  items: T[],
  search: string,
  ignoreKeys: string[] = [],
) => {
  const searchLower = search.toLowerCase();
  return items.filter((item) => {
    const matchesSearch = item.name.toLowerCase().includes(searchLower);

    return matchesSearch && !ignoreKeys.includes(item.id);
  });
};

export const makeArrayOfMinimumLength = <T>(arr: T[], minLength: number, defaultItem: T): T[] => {
  if (arr.length >= minLength) return arr;
  if (arr.length < minLength)
    return [...arr, ...Array.from({ length: minLength - arr.length }, () => ({ ...defaultItem }))];
  return Array.from({ length: minLength }, () => defaultItem);
};
