import { routePaths } from 'components/Routes/data';
import {
  DeductFromType,
  DeductionType,
  EarningPayType,
  TaxDeductionBehaviour,
} from 'components/SalaryComponents/types';
import { ValidationSchema } from 'forms';
import { FeatureFlags } from 'types';
import { DeductionsFormData, EarningsFormData } from './types';
import { checkIsDeductionTypeAdhoc, isTaxExempt } from './utils';

export const earningSteps = {
  step1: 0,
  step2: 1,
  step3: 2,
};

export const deductionsSteps = {
  step1: 0,
  step2: 1,
  step3: 2,
};

export const createEarningsRouteToStepMap = {
  [routePaths.salaryComponents.create.earnings.generalDetails]: earningSteps.step1,
  [routePaths.salaryComponents.create.earnings.taxationDetails]: earningSteps.step2,
  [routePaths.salaryComponents.create.earnings.review]: earningSteps.step3,
};

export const editEarningsRouteToStepMap = {
  [routePaths.salaryComponents.edit.earnings.generalDetails]: earningSteps.step1,
  [routePaths.salaryComponents.edit.earnings.taxationDetails]: earningSteps.step2,
  [routePaths.salaryComponents.edit.earnings.review]: earningSteps.step3,
};

export const getEarningPayTypes = (
  features: Record<FeatureFlags, boolean> | null,
): Record<
  EarningPayType,
  {
    label: string;
    description: string;
    isComingSoon?: boolean;
  }
> => {
  const isM2S2Enabled = Boolean(features?.reArchM2S2);
  return {
    fixed: {
      label: 'Monthly (fixed)',
      description: 'Paid every month. It is included in the salary structure.',
      isComingSoon: !isM2S2Enabled,
    },
    adhoc: {
      label: 'Ad hoc',
      description: 'Paid only as required. It is not a part of the salary structure.',
    },
    variable: {
      label: 'Variable',
      description:
        'Paid on a recurring basis. It is a part of the salary structure. Eg: Performance bonus.',
      isComingSoon: !isM2S2Enabled,
    },
  };
};

export const earningPayTypes: Record<
  EarningPayType,
  {
    label: string;
    description: string;
  }
> = {
  fixed: {
    label: 'Monthly (fixed)',
    description: 'Paid every month. It is included in the salary structure.',
  },
  adhoc: {
    label: 'Ad hoc',
    description: 'Paid only as required. It is not a part of the salary structure.',
  },
  variable: {
    label: 'Variable',
    description:
      'Paid on a recurring basis. It is a part of the salary structure. Eg: Performance bonus.',
  },
};

export const deductionType: Record<
  DeductionType,
  {
    label: string;
    description: string;
  }
> = {
  RECURRING: {
    label: 'Recurring (Monthly)',
    description: 'Deduction will be on a monthly basis from gross pay.',
  },
  ADHOC: {
    label: 'Ad hoc',
    description: 'You can add this deduction one time during payroll.',
  },
  NO_DEDUCTION_TYPE: {
    label: 'N/A',
    description: 'N/A',
  },
};

export const deductFrom: Record<
  DeductFromType,
  {
    label: string;
    description: string;
  }
> = {
  GROSS_PAY: {
    label: 'Gross Pay',
    description: 'Deduction will be on a monthly basis from gross pay.',
  },
  NET_PAY: {
    label: 'Net Pay',
    description: 'Deduction applied directly to Net Pay',
  },
};

export const links = {
  knowMoreDeductionsTaxability: '',
};

export const earningsTaxSections = [
  { label: 'Section 10(1)', key: 'section_10_1', description: 'Agricultural Income' },
  {
    label: 'Section 10(2)',
    key: 'section_10_2',
    description: 'Amount received by a member from a HUF (Hindu Undivided Family)',
  },
  { label: 'Section 10(10A)', key: 'section_10_10a', description: 'Commutation of Pension' },
  {
    label: 'Section 10(10C)',
    key: 'section_10_10c',
    description: 'Voluntary Retirement Scheme (VRS) compensation',
  },
  {
    label: 'Section 10(14)',
    key: 'section_10_14',
    description:
      'Special Allowances (e.g., Uniform Allowance, Conveyance Allowance, and other allowances)',
  },
];

export const deductionsTaxSections = [
  { label: 'Section 80C', key: 'section_80c', description: 'Deductions on Investments' },
  { label: 'Section 80D', key: 'section_80d', description: 'Medical Insurance Premium' },
  { label: 'Section 80DD', key: 'section_80dd', description: 'Disabled Dependent' },
  { label: 'Section 80DDB', key: 'section_80ddb', description: 'Treatment of Specified Diseases' },
  { label: 'Section 80E', key: 'section_80e', description: 'Education Loan Interest' },
  {
    label: 'Section 80EEB',
    key: 'section_80eeb',
    description: 'Interest on loan taken for the purchase of electric vehicle',
  },
  { label: 'Section 80G', key: 'section_80g', description: 'Donations' },
];

export const knowMoreItems = {
  proration: 'proration',
  arrearCalculation: 'arrear-calculation',
  wageCalculation: 'wage-calculation',
  taxability: 'taxability',
};

export const taxDeductionBehavior: Record<
  TaxDeductionBehaviour,
  { label: string; helpText: string; reviewLabel: string }
> = {
  pro_rated: {
    label: 'Prorated deduction',
    reviewLabel: 'Prorated', // the label used during the review step
    helpText:
      'Tax deduction is adjusted across the remaining months in the FY and deducted monthly.',
  },
  instant: {
    label: 'Instant deduction',
    reviewLabel: 'Instant',
    helpText: 'Deduct tax at the time of payment from the amount paid.',
  },
  not_applicable: {
    label: 'N/A',
    reviewLabel: 'N/A',
    helpText: 'No tax deduction is applicable.',
  },
};

export const earningsGeneralDetailsStepValidationSchema: ValidationSchema<EarningsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  payType: (formData) => {
    if (!formData.payType) return 'Pay frequency is required';
    return null;
  },
};

export const earningsTaxationDetailsStepValidationSchema: ValidationSchema<EarningsFormData> = {
  taxDeductionBehaviour: (formData) => {
    const payType = formData.payType;
    const isExemptInBothRegimes = Boolean(
      formData.taxExemptInNewRegime && formData.taxExemptInOldRegime,
    );
    if (payType === 'adhoc' || payType === 'variable') {
      if (isExemptInBothRegimes) return null;
      if (!formData.taxDeductionBehaviour) return 'Tax deduction behavior is required';
    }
    return null;
  },
  taxExemptionSection: (formData) => {
    const isExemptionSectionRequired = isTaxExempt(
      formData.taxExemptInNewRegime,
      formData.taxExemptInOldRegime,
    );
    if (isExemptionSectionRequired && !formData.taxExemptionSection?.[0]) {
      return 'Tax execption section is required';
    }
    return null;
  },
};

export const earningsValidationSchema: ValidationSchema<EarningsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  payType: (formData) => {
    if (!formData.payType) return 'Pay frequency is required';
    return null;
  },
  taxDeductionBehaviour: (formData) => {
    const payType = formData.payType;
    const isExemptInBothRegimes = Boolean(
      formData.taxExemptInNewRegime && formData.taxExemptInOldRegime,
    );
    if (payType === 'adhoc' || payType === 'variable') {
      if (isExemptInBothRegimes) return null;
      if (!formData.taxDeductionBehaviour) return 'Tax deduction behavior is required';
    }
    return null;
  },
  taxExemptionSection: (formData) => {
    const isExemptionSectionRequired = isTaxExempt(
      formData.taxExemptInNewRegime,
      formData.taxExemptInOldRegime,
    );
    if (isExemptionSectionRequired && !formData.taxExemptionSection?.[0]) {
      return 'Tax execption section is required';
    }
    return null;
  },
};

export const deductionsCreationGeneralStepValidationSchema: ValidationSchema<DeductionsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  deductionType: (formData) => {
    if (!formData.deductionType) return 'Deduction type is required';
    return null;
  },
};

export const deductionsCreationPayAndTaxabiltyStepValidationSchema: ValidationSchema<DeductionsFormData> =
  {
    deductFrom: (formData) => {
      if (checkIsDeductionTypeAdhoc(formData.deductionType) && !formData.deductFrom) {
        return 'Deduct from section is required';
      }
      return null;
    },
    taxExemptionSection: (formData) => {
      if (
        isTaxExempt(formData.taxExemptInOldRegime, formData.taxExemptInNewRegime) &&
        !formData.taxExemptionSection
      ) {
        return 'Exempted from section is required';
      }
      return null;
    },
  };

export const deductionsValidationSchema: ValidationSchema<DeductionsFormData> = {
  name: (formData) => {
    if (!formData.name) return 'Component name is required';
    return null;
  },
  displayName: (formData) => {
    if (!formData.displayName) return 'Display name is required';
    return null;
  },
  deductionType: (formData) => {
    if (!formData.deductionType) return 'Pay frequency is required';
    return null;
  },
  deductFrom: (formData) => {
    if (formData.deductionType === 'ADHOC' && !formData.deductFrom) {
      return 'Deduct from section is required';
    }
    return null;
  },
  taxExemptionSection: (formData) => {
    if (
      isTaxExempt(formData.taxExemptInOldRegime, formData.taxExemptInNewRegime) &&
      !formData.taxExemptionSection
    ) {
      return 'Exempted from section is required';
    }
    return null;
  },
};

export const createDeductionsRouteToStepMap = {
  [routePaths.salaryComponents.create.deductions.generalDetails]: deductionsSteps.step1,
  [routePaths.salaryComponents.create.deductions.payAndTaxDetails]: deductionsSteps.step2,
  [routePaths.salaryComponents.create.deductions.review]: deductionsSteps.step3,
};

export const editDeductionsRouteToStepMap = {
  [routePaths.salaryComponents.edit.deductions.generalDetails]: deductionsSteps.step1,
  [routePaths.salaryComponents.edit.deductions.payAndTaxDetails]: deductionsSteps.step2,
  [routePaths.salaryComponents.edit.deductions.review]: deductionsSteps.step3,
};

export const basicAndDaComponents = ['Basic', 'Dearness Allowance'];
