import { gql } from 'graphql-request';
import { graphQLApi } from 'utils/Api';
import {
  GraphQLResponse,
  PayrollGetUserByIdArgs,
  PayrollPeopleOrganization,
} from '../salaryComponents/types';

const getByUserIdQuery = gql`
  query PayrollGetUser($userId: String!) {
    payrollGetUser(userId: $userId) {
      data {
        id
        name
        organizationId
        peopleId
        hireDate
        employeeType
        userRoleId
        title
        salary
        lastLogin
        isActive
        deactivateDate
        noTds
        pastSalary
        pastTds
        imprest
        uan
        isPf
        esiIp
        stopSalary
        epContractorPaid
        bankNameId
        phoneNumber
        gender
        dob
        location
        isPt
        customSalaryStructure
        salaryModel
        employeeId
        managerPeopleId
        isLoginEnabled
        advanceSalary
        optionalUserData
        department
        notes
        isCompany
        perquisites
        emi
        isEsiCompulsory
        leaves
        shiftStart
        shiftEnd
        photo
        contactPreferences
        isPfAdded
        previousEmployerSalary
        previousEmployerTds
        pastExemption
        isEsicAdded
        variablePay
        isDeleted
        isPfEpsEnabled
        isEsiDisabled
        pfLimit
        isResidentOfIndia
        createdAt
        updatedAt
        locationPt
        idPrefix
        uiPreferences
        communicationPreferences
        employeeTdsOverridePurpose
        flexibleBenefitsAmountHold
        locationLwf
        npsPran
        npsConfig
        reasonForLeaving
      }
      code
      success
      message
    }
  }
`;

export const getUserbyId = async (queryVariables: PayrollGetUserByIdArgs) => {
  return graphQLApi<
    GraphQLResponse<'payrollGetUser', PayrollPeopleOrganization>,
    PayrollGetUserByIdArgs
  >({
    query: getByUserIdQuery,
    queryVariables,
  });
};
