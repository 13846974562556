import {
  PayTypeFilterType,
  SalaryComponentStatusType,
} from 'api/gql-utils/salaryComponents/types';
import { DeductionType } from './types';

export const tabItems = {
  earnings: 'earnings',
  deductions: 'deductions',
};

export const statusFilters = {
  all: 'All Components',
  active: 'Active',
  disabled: 'Disabled',
};

export const earningPayTypeFilters = {
  fixed: 'Fixed',
  adhoc: 'Adhoc',
  variable: 'Variable',
};

export const taxabilityFilters = {
  'old-regime': 'Tax exempt in Old Regime',
  'new-regime': 'Tax exempt in New Regime',
};

export const earningFiltersMap = {
  payType: 'payType',
  taxability: 'taxability[]',
  status: 'status',
};

export const earningFilersLabelMap = {
  [earningFiltersMap.payType]: 'Pay Type',
  [earningFiltersMap.taxability]: 'Taxability',
  [earningFiltersMap.status]: 'Status',
};

export const earningFilterWithOptionLabelMap = {
  [earningFiltersMap.payType]: earningPayTypeFilters,
  [earningFiltersMap.taxability]: taxabilityFilters,
};

export const deductionTypeFilters = {
  monthly: 'Recurring(Monthly)',
  adhoc: 'Adhoc',
};

export const deductionFiltersMap = {
  deduction_type: 'deduction_type',
  taxability: 'taxability[]',
  status: 'status',
};

export const deductionFilersLabelMap = {
  [deductionFiltersMap.deduction_type]: 'Deduction Type',
  [deductionFiltersMap.taxability]: 'Taxability',
  [deductionFiltersMap.status]: 'Status',
};

export const deductionFilterWithOptionLabelMap = {
  [deductionFiltersMap.deduction_type]: deductionTypeFilters,
  [deductionFiltersMap.taxability]: taxabilityFilters,
};

export const ModalActions = {
  disable: 'disable',
  enable: 'enable',
  delete: 'delete',
};

export const helpArticles = {
  incomeTaxArticale: '',
  learnMore: '',
};

export const salaryComponentsQueries = {
  getSalaryComponentById: 'getSalaryComponentById',
  getSalaryComponentsList: 'getSalaryComponentsList',
  getSalaryComponentDetailsQuery: 'getSalaryComponentDetailsQuery',
  enableComponentQuery: 'enableComponentQuery',
  disableComponentQuery: 'disableComponentQuery',
  deleteComponentQuery: 'deleteComponentQuery',
};

export const salaryComponentParams = {
  componentId: 'componentId',
  knowMore: 'knowMore',
  modalAction: 'action',
  earningPayType: 'earningPayType',
};

export const SalaryComponentCategory = {
  NONE_CATEGORY: 'NONE_CATEGORY',
  EARNINGS_CATEGORY: 'EARNINGS_CATEGORY',
  DEDUCTIONS_CATEGORY: 'DEDUCTIONS_CATEGORY',
  NON_PAYABLE_BENEFITS_CATEGORY: 'NON_PAYABLE_BENEFITS_CATEGORY',
  PERQUISITES_CATEGORY: 'PERQUISITES_CATEGORY',
  BONUS_CATEGORY: 'BONUS_CATEGORY',
};

export const SalaryComponentStatus = {
  COMPONENT_STATUS_NONE: 'COMPONENT_STATUS_NONE',
  COMPONENT_STATUS_ACTIVE: 'COMPONENT_STATUS_ACTIVE',
  COMPONENT_STATUS_INACTIVE: 'COMPONENT_STATUS_INACTIVE',
};

export const EarningPayTypeFilter = {
  LIST_ADHOC_TYPE: 'LIST_ADHOC_TYPE',
  LIST_VARIABLE_TYPE: 'LIST_VARIABLE_TYPE',
  LIST_FIXED_TYPE: 'LIST_FIXED_TYPE',
  LIST_RECURRING_TYPE: 'LIST_RECURRING_TYPE',
};

export const payTypeFiltersMap: Record<string, PayTypeFilterType> = {
  fixed: 'LIST_FIXED_TYPE',
  adhoc: 'LIST_ADHOC_TYPE',
  variable: 'LIST_VARIABLE_TYPE',
} as const;

export const deductionTypeFiltersMap: Record<string, PayTypeFilterType> = {
  monthly: 'LIST_RECURRING_TYPE',
  adhoc: 'LIST_ADHOC_TYPE',
} as const;

export const statusFiltersMap: Record<string, SalaryComponentStatusType> = {
  all: 'COMPONENT_STATUS_NONE',
  active: 'COMPONENT_STATUS_ACTIVE',
  disabled: 'COMPONENT_STATUS_INACTIVE',
} as const;

export const MIN_COLLAPSIBLE_LENGTH = 43;
